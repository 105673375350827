import axios from 'axios';
import {
  USER_TYPE_PROSKER,
  PROFILE_IMG,
  BANNER_IMG,
  FULLNAME,
  CATEGORY,
  MERCADOPAGO_ACCOUNT,
  SKILL,
  ADDRESS } from '@/misc/constants';

export default {
  namespaced: true,
  state: {
    token: null,
    percentage: null,
    missingData: [],
    currentUser: null,
    signUpUserType: USER_TYPE_PROSKER
  },
  getters: {
    authenticated (state) {
      return state.token;
    },
    currentUser (state) {
      return state.currentUser;
    },
    percentage (state) {
      return state.percentage;
    },
    missingData (state) {
      return state.missingData;
    },
    signUpUserType (state) {
      return state.signUpUserType;
    }
  },
  mutations: {
    SET_TOKEN (state, token) {
      state.token = token;
    },
    SET_CURRENT_USER (state, data) {
      state.currentUser = data;
    },
    SET_PERCENTAGE (state, data) {
      state.percentage = data;
    },
    SET_MISSING_DATA (state, data) {
      state.missingData = data;
    },
    SET_SIGN_UP_USER_TYPE (state, data) {
      state.signUpUserType = data;
    }
  },
  actions: {
    updateSignUpUserType ({ commit }, value) {
      commit('SET_SIGN_UP_USER_TYPE', value);
    },
    async signIn ({ dispatch }, credentials) {
      let response = await axios.post(
        process.env.VUE_APP_BASE_URL + '/api/login',
        credentials
      );
      return dispatch('attempt', response.data.access_token);
    },
    async signInProvider ({ dispatch }, currentUserToken) {
      return dispatch('attempt', currentUserToken);
    },
    async attempt ({ commit, state }, token) {
      commit('SET_TOKEN', null);
      commit('SET_CURRENT_USER', null);
      if (token) {
        commit('SET_TOKEN', token);
      }
      if (!state.token) {
        return;
      }
      try {
        let response = await axios.get(
          process.env.VUE_APP_BASE_URL + '/api/user'
        );
        await axios
          .get(
            process.env.VUE_APP_BASE_URL +
              '/api/users?filters=id=' +
              response.data.id
          )
          .then((response) => {
            response.data.data.forEach((user) => {
              let percentage = 100;
              const missingData = [];
              if (user.type_user >= USER_TYPE_PROSKER) {
                if (!user.profile_img) {
                  missingData.push(PROFILE_IMG);
                  percentage -= 14; // 100/7
                }
                if (!user.first_name || !user.last_name) {
                  missingData.push(FULLNAME);
                  percentage -= 14;
                }
                if (user.category_name.length === 0) {
                  missingData.push(CATEGORY);
                  percentage -= 14;
                }
                if (user.skill_name.length === 0) {
                  missingData.push(SKILL);
                  percentage -= 14;
                }
                if (!user.banner_img) {
                  missingData.push(BANNER_IMG);
                  percentage -= 14;
                }
                if (user.addresses.length === 0) {
                  missingData.push(ADDRESS);
                  percentage -= 14;
                }
                if (user.type_user >= USER_TYPE_PROSKER && !user.mp_access_token) {
                  missingData.push(MERCADOPAGO_ACCOUNT);
                  percentage -= 14;
                }
              } else {
                if (!user.profile_img) {
                  missingData.push(PROFILE_IMG);
                  percentage -= 50; // 100/2
                }
                if (!user.first_name || !user.last_name) {
                  missingData.push(FULLNAME);
                  percentage -= 50;
                }
              }

              commit('SET_PERCENTAGE', percentage);
              commit('SET_MISSING_DATA', missingData);
              commit('SET_CURRENT_USER', user);
            });
          });
      } catch (e) {
        commit('SET_TOKEN', null);
        commit('SET_CURRENT_USER', null);
        this.$swal.fire({
          text: this.$t('loginGoogleErrorMessage')
        }).then(() => {
          this.$router.push({ name: 'auth1.sign-in1' });
        });
      }
    },
    async updatePercentage (context, currentUser) {
      await axios
        .get(
          process.env.VUE_APP_BASE_URL + '/api/users?filters=id=' + currentUser
        )
        .then((response) => {
          response.data.data.forEach((user) => {
            let percentage = 100;
            const missingData = [];
            if (user.type_user >= USER_TYPE_PROSKER) {
              if (!user.profile_img) {
                missingData.push(PROFILE_IMG);
                percentage -= 14; // 100/7
              }
              if (!user.first_name || !user.last_name) {
                missingData.push(FULLNAME);
                percentage -= 14;
              }
              if (user.category_name.length === 0) {
                missingData.push(CATEGORY);
                percentage -= 14;
              }
              if (user.skill_name.length === 0) {
                missingData.push(SKILL);
                percentage -= 14;
              }
              if (!user.banner_img) {
                missingData.push(BANNER_IMG);
                percentage -= 14;
              }
              if (user.addresses.length === 0) {
                missingData.push(ADDRESS);
                percentage -= 14;
              }
              if (user.type_user >= USER_TYPE_PROSKER && !user.mp_access_token) {
                missingData.push(MERCADOPAGO_ACCOUNT);
                percentage -= 14;
              }
              if (percentage < 14) {
                percentage = 0; // round
              }
            } else {
              if (!user.profile_img) {
                missingData.push(PROFILE_IMG);
                percentage -= 50; // 100/2
              }
              if (!user.first_name || !user.last_name) {
                missingData.push(FULLNAME);
                percentage -= 50;
              }
            }
            context.commit('SET_PERCENTAGE', percentage);
            context.commit('SET_MISSING_DATA', missingData);
          });
        });
    },
    async updateUser (context, currentUser) {
      await axios
        .get(
          process.env.VUE_APP_BASE_URL + '/api/users?filters=id=' + currentUser
        )
        .then((response) => {
          response.data.data.forEach((user) => {
            context.commit('SET_CURRENT_USER', user);
          });
        });
    },
    signOut ({ commit }) {
      return axios
        .post(process.env.VUE_APP_BASE_URL + '/api/logout')
        .then(() => {
          commit('SET_TOKEN', null);
          commit('SET_CURRENT_USER', null);
        });
    }
  }
};
