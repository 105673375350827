<template>
  <v-app id="inspire">
    <router-view :key="$route.fullPath" />
  </v-app>
</template>
<script>
export default {
  name: 'App',
  components: {},
  mounted () {}
};
</script>
<style lang="scss">
@import "assets/scss/style.scss";
</style>
