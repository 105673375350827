import Vue from 'vue';
import Vuex from 'vuex';
import Setting from './Setting/index';
import auth from '@/store/auth';
import search from '@/store/search';
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    Setting,
    auth,
    search
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {},
  strict: debug
});
