export const siteRoutes = {
  'home-proskers': {
    'uy': 'proskers',
    'br': 'proskers'
  },
  'home-clients': {
    'uy': 'clientes',
    'br': 'clientes'
  },
  'subscription': {
    'uy': 'suscripcion',
    'br': 'assinatura'
  },
  'about': {
    'uy': 'nosotros',
    'br': 'nos'
  },
  'sell': {
    'uy': 'como-vender',
    'br': 'como-vender'
  },
  'buy': {
    'uy': 'como-comprar',
    'br': 'como-comprar'
  },
  'my-subscription': {
    'uy': 'mi-suscripcion',
    'br': 'minha-assinatura'
  },
  'terms': {
    'uy': 'terminos-condiciones',
    'br': 'termos-condicoes'
  },
  'my-address': {
    'uy': 'mi-direccion',
    'br': 'minha-direcao'
  },
  'contact': {
    'uy': 'contacto',
    'br': 'contato'
  },
  'favorites': {
    'uy': 'mis-favoritos',
    'br': 'meus-favoritos'
  },
  'orders': {
    'uy': 'mis-ordenes',
    'br': 'minhas-ordens'
  },
  'billing': {
    'uy': 'facturacion',
    'br': 'cobranca'
  },
  'plans': {
    'uy': 'planes-suscripcion',
    'br': 'planos-assinatura'
  },
  'profile': {
    'uy': 'perfil/:query',
    'br': 'perfil/:query'
  },
  'profiles': {
    'uy': 'perfiles',
    'br': 'perfiles'
  },
  'services': {
    'uy': 'servicios/:query',
    'br': 'servicos/:query'
  },
  'hired-services': {
    'uy': 'servicios-contratados',
    'br': 'servicos-contratados'
  },
  'category': {
    'uy': 'categorias/:name',
    'br': 'categorias/:name'
  },
  'categories': {
    'uy': 'categorias',
    'br': 'categorias'
  },
  'forgot-pass': {
    'uy': 'recuperar-contrasena',
    'br': 'recuperar-senha'
  },
  'checkout-service': {
    'uy': '/pago-de-servicio/:serviceId',
    'br': '/pagamento-de-servico/:serviceId'
  },
  'checkout-subscription': {
    'uy': '/pago-de-suscripcion/:subscriptionId',
    'br': '/pagamento-de-assinatura/:subscriptionId'
  }
};
