import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const state = {
  users: [
    {
      id: 'sh46s546sdg564sdffs4hsd6fh',
      name: 'Admin SocialV',
      mobileNo: null,
      email: 'admin@socialvue.com',
      profileImage: null,
      password: 'admin123'
    }
  ],
  activePage: {
    name: 'Dashboard',
    breadCrumb: [
      {
        html: '<i class="ri-home-4-line mr-1 float-left"></i>Home',
        to: { name: 'mini.dashboard.home-1' }
      },
      {
        text: '',
        href: '#'
      }
    ]
  },
  layoutMode: {
    dark: false,
    rtl: false
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
