export default {
  namespaced: true,
  state: {
    searchText: null
  },
  getters: {
    searchText (state) {
      return state.searchText;
    }
  },
  mutations: {
    SET_SEARCH_TEXT (state, searchText) {
      state.searchText = searchText;
    }
  },
  actions: {
    updateSearchText (context, searchText) {
      context.commit('SET_SEARCH_TEXT', searchText);
    }
  }
};
