// User profile tabs
export const PROFILE_TAB = 0;
export const REVIEWS_TAB = 1;
export const PREFERENCES_TAB = 2;
export const SERVICES_TAB = 3;
export const ORDERS_TAB = 4;
export const CHAT_TAB = 5;
export const CALENDAR_TAB = 6;
export const SUBSCRIPTION_TAB = 7;
export const CONTACT_TAB = 8;
export const BUY_TAB = 9;
export const OFFERS_TAB = 10;

// Global
export const USER_TYPE_CLIENT = 0;
export const USER_TYPE_PROSKER = 10;
export const USER_TYPE_ADMIN = 20;

// CRYPTO
export const AES_SECRET = 'Jkln012afAdl16Bp';

// Jobs Status
export const JOB_STATUS_NEW = 10;
export const JOB_STATUS_REJECTED = 20;
export const JOB_STATUS_EXPIRED = 21;
export const JOB_STATUS_READY_TO_EXECUTE = 30;
export const JOB_STATUS_IN_PROGRESS = 40;
export const JOB_STATUS_PROSKER_CANCEL = 50;
export const JOB_STATUS_CLIENT_CANCEL = 60;
export const JOB_STATUS_PROSKER_COMPLETE = 70;
export const JOB_STATUS_CLIENT_REJECTED = 80;
export const JOB_STATUS_COMPLETED = 90;
export const JOB_STATUS_PROSKER_HELP_REQUEST = 100;
export const JOB_STATUS_CLIENT_HELP_REQUEST = 110;

// Features (for feature flagging)
export const FEATURE_CALENDAR = 'calendar';
export const FEATURE_JOBS = 'jobs';
export const FEATURE_SUBSCRIPTIONS = 'subscriptions';
export const FEATURE_PUBLICATIONS_CAROUSEL = 'publications carousel';
// ROUTES
export const HOME_ROUTE = 'social.list';
// PROFILE COMPLETION
export const PROFILE_IMG = 1;
export const BANNER_IMG = 2;
export const FULLNAME = 3;
export const CATEGORY = 4;
export const MERCADOPAGO_ACCOUNT = 5;
export const SKILL = 6;
export const ADDRESS = 7;
// Facets Names

export const FACET_CITY_FIELD_NAME = 'cities.id';
export const FACET_SKILLS_FIELD_NAME = 'skills.id';
export const FACET_CATEGORY_FIELD_NAME = 'categories.id';
